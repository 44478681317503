import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import axios from 'axios';
import "../CompanyScorecardMobile.css";
import SearchIcon from '../images/search-icon.svg'
import goldBadge from '../images/gold_badge.png'
import basicBadge from '../images/basic_badge.png'
import CompanyProfileMobile from './CompanyProfile-Mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from './LoadingSpinner';
import CompanyScorecardPdf from './CompanyScorecardPdf';

const CompanyScorecardMobile = () => {
  const [showCompanyProfile, setShowCompanyProfile] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [companyDataDuplicate, setCompanyDataDuplicate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [stock_code, setstock_code] = useState('');
  const itemsPerPage = 10;
  const [sortBy, setSortBy] = useState(null);
  const [subsortBy, setSubSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [timestamp, setTimestamp] = useState(null);

  const handleSort = (columnName, subColumnName = null) => {
    setCurrentPage(1);
    if (sortBy === columnName) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSubSortBy(subColumnName);
      setSortBy(columnName);
      setSortOrder('asc');
    }
  };

  const handleTimestamp = () => {
    if (companyDataDuplicate.length > 0) {
      const maxTimestamp = Math.max(...companyDataDuplicate.map(obj => obj.timestamp));

      const timestampInMilliseconds = maxTimestamp * 1000;
      // Create a new Date object from the timestamp
      const dateObject = new Date(timestampInMilliseconds);

      // Format the date as "Month, Year" using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long'
      }).format(dateObject);

      setTimestamp(formattedDate);
    }
  }

  useEffect(() => {
    handleTimestamp();
  }, [companyDataDuplicate])

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

// Creating the params object to include both searchQuery and stock_code
   const params = {
    name: searchQuery || undefined,  //  for company name search
    stock_code: stock_code || undefined,  // for stock code search
  };

  // Passing the params to the API call
    axios.post('https://wwi-questionnaire-v2.azurewebsites.net/api/getCompanyList', config)
      .then(response => {
        // const goldMembers = response.data?.list.filter(item => item.membership === 'GOLD');
        setCompanyData(response.data?.list);
        setCompanyDataDuplicate(response.data?.list);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        setLoading(false);
      });
  }, []);

  const handleSearchQuery = () => {
    if (!searchQuery && !stock_code) {
      // If search query is empty, reset to the original data
      setCompanyData(companyDataDuplicate);
    } else {
       // Convert the search query to lowercase for case-insensitive comparison
          const lowerCaseQuery = searchQuery.toLowerCase();
          const lowerCaseStockCode = stock_code.toLowerCase();

    // Filter based on both name and stock code
    const filteredData = companyDataDuplicate.filter((company) => {
      const companyName = company.data['0.1']?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLowerCase() || '';
      const companyStockCode = company.stock_code?.toLowerCase() || '';
      return companyName.includes(lowerCaseQuery) || companyStockCode.includes(lowerCaseStockCode);
    });

    setCompanyData(filteredData);  // Updating the companyData with the filtered result
    setCurrentPage(1);  // Reset to the first page after filtering
  }
};



  useEffect(() => {
    handleSearchQuery();
  }, [searchQuery]);

  const handleBackArrowClick = () => {
    setShowCompanyProfile(!showCompanyProfile)
    setCompanyId(null);
  };

  const handleCompanyClick = (companyId) => {
    setCompanyId(companyId);
    setShowCompanyProfile(!showCompanyProfile);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  const sortedItems = companyData.slice().sort((a, b) => {
    if (sortBy && sortBy != 'dummy') {
      let aValue = '';
      let bValue = '';
      if (subsortBy) {
        aValue = a.data[sortBy]?.[subsortBy] || '';
        bValue = b.data[sortBy]?.[subsortBy] || '';
      }
      else {
        if (a?.data?.[sortBy]) {
          aValue = a.data[sortBy] || '';
          bValue = b.data[sortBy] || '';
        } else {
          aValue = a[sortBy] || '';
          bValue = b[sortBy] || '';
        }
      }

      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    return 0;
  });

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDownloadClick = () => {
    const printContent = <CompanyScorecardPdf companyData={companyData} />;
    const printHtml = ReactDOMServer.renderToString(printContent);

    const container = document.createElement('div');
    container.innerHTML = printHtml;
  
    const options = {
      margin: [15, 0, 15, 0],
      filename: 'company-list',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };    
    html2pdf(container, options);
  };

  return loading ? <LoadingSpinner/> : !showCompanyProfile ? (
    <div className="company-scorecard">
        <div className='header-container'>
            <span>Company Dashboard</span>
        </div>
        <div className='company-scorecard-content'>
          <div className='certification-status-update'>
            <p className='cert-header-mobile'>
              Certification status update: <span className='cert-header-date'>{timestamp}</span>
            </p>
            <div className='cert-line'/>
            <div className='cert-text'>
              WWi assesses Asia-based corporates and other types of organisation on their public disclosure regarding their governance, polices and practices related to women.
              The WWi Database, with employer disclosure information on women workplace representation and practices of over 250 listed and multinational companies in a variety of sectors, will be publicly released on the WWi website as from May 2024.
              {/* <span className='cert-read-more'> Read more...</span> */}
            </div>
          </div>
          <div className='search-filter-download-mobile'>
            <div className='cert-search-div-mobile w-2/3'>
              <div className='cert-search-mobile'>
                <input
                  type='text'
                  placeholder='Search by company name or stock code'
                  className='mx-2 cert-search-text h-full w-full'
                  value={searchQuery}
                  onChange={(e) => {
                    const query = e.target.value;
                    setSearchQuery(query);
                    setstock_code(query);  // Checking if the query is a valid stock code pattern here
                  }}

                />
                <img src={SearchIcon} alt='search' className='cert-search-icon-mobile'></img></div>
            </div>
            {/* <div className='cert-filter-mobile'><span className='span1-mobile'>___</span><span className='span2-mobile'>__</span><span className='span3-mobile'>_</span></div> */}
            <div className='cert-download-mobile' onClick={handleDownloadClick}><div className='span4-mobile'>Download</div></div>
          </div>
        </div>
        <div className='company-details-table-container-mobile'>
          <table className='company-details-table-mobile'>
            <thead className='thead-mobile'>
              <tr className='tr-mobile'>
                <th className='th-mobile' rowSpan={2}>Entity name</th>
                <th className='th-mobile' rowSpan={2} onClick={() => handleSort('membership')} style={{ cursor: 'pointer' }}>
                  Membership status <br/><br/>{sortBy === 'membership' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' rowSpan={2} onClick={() => handleSort('stock_code')} style={{ cursor: 'pointer' }}>
                  Stock code <br/><br/>{sortBy === 'stock_code' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' colSpan={5}>Selected Transparency Indicators</th>
                <th className='th-mobile' rowSpan={2} onClick={() => handleSort('0.5', '0')} style={{ cursor: 'pointer' }}>
                  Sector <br/><br/>{sortBy === '0.5' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' rowSpan={2} onClick={() => handleSort('0.7.a')} style={{ cursor: 'pointer' }}>
                  Entity Global HQ <br/><br/>{sortBy === '0.7.a' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' rowSpan={2} onClick={() => handleSort('0.7.b')} style={{ cursor: 'pointer' }}>
                  Entity Asia HQ <br/><br/>{sortBy === '0.7.b' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
              </tr>
              <tr className='tr-mobile'>
                <th className='th-mobile' onClick={() => handleSort('dummy')} style={{ cursor: 'pointer' }}>
                  Track % women in workforce by level <br/><br/>{sortBy === 'dummy' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' onClick={() => handleSort('12.1', '0')} style={{ cursor: 'pointer' }}>
                  Provide employee training or capacity building on gender issues <br/><br/>{sortBy === '12.1' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' onClick={() => handleSort('12.2', '0')} style={{ cursor: 'pointer' }}>
                  Provide women-focused talent/leadership program <br/><br/>{sortBy === '12.2' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' onClick={() => handleSort('4.1', '0')} style={{ cursor: 'pointer' }}>
                  Provide parental leaves beyond statutory requirements <br/><br/>{sortBy === '4.1' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
                <th className='th-mobile' onClick={() => handleSort('7.1', '0')} style={{ cursor: 'pointer' }}>
                  Track any gender pay metrics <br/><br/>{sortBy === '7.1' ? (sortOrder === 'asc' ? '▲' : '▼') : <FontAwesomeIcon icon={faBars} />}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.map(company => (
                <tr className='tr-mobile' key={company.id}>
                  <td className='td-mobile' style={{cursor:"pointer"}} onClick={() => handleCompanyClick(company.id)}>{company.data['0.1']}</td>
                  <td className='td-mobile'>{(() => {
                    switch (company.membership) {
                      case "GOLD":
                        return <img src={goldBadge} alt="gold" className='w-[4rem] m-auto'/>
                      case "BASIC":
                        return <img src={basicBadge} alt="basic" className='w-[4rem] m-auto'/>
                      default:
                        return <div>IN-SCOPE</div>
                    }
                  })()}</td>
                  <td className='td-mobile'>{company.data['0.3.b']}</td>
                  <td className='td-mobile green-tick'>✓</td>
                  {company.data['12.1']?.[0] == 'Y' ? (
                    <td className='td-mobile green-tick'>✓</td>
                  ) : (
                    <td className='td-mobile'>✘</td>
                  )}
                  {company.data['12.2']?.[0] == 'Y' ? (
                    <td className='td-mobile green-tick'>✓</td>
                  ) : (
                    <td className='td-mobile'>✘</td>
                  )}
                  {company.data['4.1']?.[0] == 'Y' ? (
                    <td className='td-mobile green-tick'>✓</td>
                  ) : (
                    <td className='td-mobile'>✘</td>
                  )}
                  {(company.data['7.1']?.[0] == 'Y' && 
                  company.data['7.1'] != 'Yes – We have an equal pay policy in place, but we currently do not track gender pay metrics') ? (
                    <td className='td-mobile green-tick'>✓</td>
                  ) : (
                    <td className='td-mobile'>✘</td>
                  )}
                  <td className='td-mobile'>{company.data['0.5'] || "N/A"}</td>
                  <td className='td-mobile'>{company.data['0.7.a'] || "N/A"}</td>
                  <td className='td-mobile'>{company.data['0.7.b'] || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-mobile my-4 mx-4">
          <span className='mr-2'>Page</span>
          {Array.from({ length: Math.ceil(companyData.length / itemsPerPage) }).map(
            (_, index) => (
              <span
                key={index}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active-page mx-2" : "" + "mx-2 inactive-page"}
              >
                {index + 1}
              </span>
            )
          )}
        </div>
      </div>
    ) : (
      <CompanyProfileMobile companyId={companyId} handleBackArrowClick={handleBackArrowClick} />
    );
}

export default CompanyScorecardMobile;