import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../LatestNews.css";

const useScript = (url, containerId) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.defer = true;

    const container = document.getElementById(containerId);
    container.appendChild(script);

    return () => {
      container.removeChild(script);
    };
  }, [url, containerId]);
};

const LatestNews = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const interval = setInterval(() => {
      const juicerLogo = document.querySelector(".referral");
      if (juicerLogo) {
        juicerLogo.style.opacity = "0";
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div className="latest-news-header-bg">
        <div className="latest-news-header">
          <div className="latest-news-heading">{t("news.whatsNew")}</div>
          <p className="latest-news-line" />
        </div>
      </div>
      <div id="linkedin-div" className="latest-news-linkdin">
        {useScript(
          "https://www.juicer.io/embed/womenworkplaceindex-441f4818-9768-42c0-bcd2-abb34023fc46/embed-code.js",
          "linkedin-div"
        )}
      </div>
    </>
  );
};

export default LatestNews;
