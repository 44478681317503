
import EOC from "../images/logos/EOC.png";
import HKGCC from "../images/logos/HKGCC_Logo.png";
import BEC from "../images/logos/BEC1.jpeg";
import CPA from "../images/logos/CPA01.jpeg";
import HKIEND from "../images/logos/HKIEND.png";
import TWF from "../images/logos/TWF.png";
import WIFA from "../images/logos/WIFA.png";
import AmCham from "../images/logos/AmCham.png";
import SCMP from "../images/logos/SCMP.png";
import Manulife from "../images/logos/Manulife.png";
import CLP from "../images/logos/CLP.jpg";
import HKUST from "../images/logos/HKUST.jpg";
import Linklaters from "../images/logos/Linklaters.png";
import Sino from "../images/logos/Sino.jpeg";
import Irish from "../images/logos/IrishChamber.png"
import "../home.css";

import { useTranslation } from "react-i18next";

const OrganisationLogos = () => {
  const { t } = useTranslation();
  const membersImages = [
    Manulife,
    CLP,
    Sino,
    HKUST,
    Linklaters,
    BEC,
    WIFA
  ];
  const supportOrgImages = [
    EOC,
    HKGCC,
    BEC,
    CPA,
    HKIEND,
    TWF,
    WIFA,
    AmCham,
    SCMP,
    Irish
  ]; 
  return (
    <div className="my-2 mx-12">
      <div className="my-4 supporting-organisations-title">
        {t("homepage.foudingMembersTitle")}
      </div>
      <div className="flex flex-col sm:flex-row sm:flex-wrap">
        {membersImages.map((imageUrl, index) => (
          <div key={index} className="w-full sm:w-1/3 mb-12 sm:mb-6">
            <img
              src={imageUrl}
              alt={`Image ${index + 1}`}
              className="h-full w-full sm:w-auto sm:max-h-[90px] sm:max-w-[280px] my-auto mx-auto object-contain"
            />
          </div>
        ))}
      </div>
      <div className="my-4 supporting-organisations-title">
        {t("homepage.supportingOrganisationsTitle")}
      </div>
      <div className="flex flex-col sm:flex-row sm:flex-wrap">
        {supportOrgImages.map((imageUrl, index) => (
          <div key={index} className="w-full sm:w-1/3 mb-12 sm:mb-6">
            <img
              src={imageUrl}
              alt={`Image ${index + 1}`}
              className="h-full w-full sm:w-auto sm:h-[90px] sm:max-w-[280px] my-auto mx-auto object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrganisationLogos;