import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LanguageHashLink({ to, children, ...props }) {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const localizedPath = `/${lang}${to.startsWith("/") ? "" : "/"}${to}`;

  return (
    <Link to={localizedPath} {...props}>
      {children}
    </Link>
  );
}
