import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../BecomeAWWIEmployer.css";
import crossLogoRed from "../images/crosslogored.png";
import checkMarkGif from "../images/icons8-checkmark.gif";
import emailjs from "emailjs-com";
import Modal from "react-bootstrap/Modal";

const SERVICE_ID = "service_49abyxy";
const TEMPLATE_ID = "template_0gjw9cq";
const USER_ID = "MoUOZfHb4t2gNsEFj";

const BecomeAWWIEmployer = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className={`become-a-wwi-employer ${showModal ? "blur-effect" : ""}`}>
      <div className="header-image-container">
        <div className="image">
          <p
            className="main-heading"
            dangerouslySetInnerHTML={{
              __html: t("joinUs.headerTitle"),
            }}
          ></p>
        </div>
      </div>
      <div className="content">
        <div className="sub-sections">
          <div className="text-section">
            <div className="title">{t("joinUs.introText")}</div>
            <div className="become-a-wwi-text-paragraph">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("joinUs.mainDescription"),
                }}
              ></span>
              <br />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("joinUs.recognitionList"),
                }}
              ></span>
              <br />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("joinUs.callToAction"),
                }}
              ></span>
              <br />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("joinUs.formInterestStatement"),
                }}
              ></span>
            </div>
            <div
              id="join-us-form"
              className="form-data"
              onSubmit={handleOnSubmit}
            >
              <form className="become-a-wwi-employer-form">
                <input
                  required
                  type="text"
                  name="company_name"
                  placeholder={t("contactUs.form.placeholders.companyName")}
                  className="input-field"
                />
                <input
                  required
                  type="text"
                  name="person_name"
                  placeholder={t("contactUs.form.placeholders.personName")}
                  className="input-field"
                />
                <input
                  required
                  type="text"
                  name="email"
                  placeholder={t("contactUs.form.placeholders.email")}
                  className="input-field"
                />
                <input
                  type="submit"
                  value={t("contactUs.form.submitButton")}
                  className="submit-btn"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal-overlay">
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Body>
              <img
                src={crossLogoRed}
                alt="Cross Logo"
                onClick={handleCloseModal}
                style={{
                  width: "50px",
                  height: "50px",
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                }}
              />
              <p className="contact-ty-text">
                {t("contactUs.modal.thankYouShort")}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={checkMarkGif}
                  alt="Check Mark"
                  style={{ marginTop: "1rem", width: "5rem", height: "5rem" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="contact-ty-text-2"
                  dangerouslySetInnerHTML={{
                    __html: t("contactUs.modal.thankYouLong"),
                  }}
                ></p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default BecomeAWWIEmployer;
