import "../SupportingOrganisations.css";
import OrganisationLogos from "./OrganisationLogos";

import { useTranslation } from "react-i18next";

const SupportingOrganisations = () => {   
  const { t } = useTranslation();
  
  return (
    <div className="flex flex-col justify-center">
      <div className='supporting-organisations-header flex flex-col justify-center'>
        <p className='supporting-organisations-heading text-[30px] sm:text-[48px] leading-[50px]'>
          {t("homepage.supportingOrganisationsTitle")}
        </p>
      </div>
      <OrganisationLogos/>
    </div>
  )
}

export default SupportingOrganisations;