import "../footer.css";
import footerLogo from "../images/footerLogo.png";
import facebookLogo from "../images/facebook.png";
import linkedinLogo from "../images/linkedin.png";
import instagramLogo from "../images/instagram.png";
import LanguageHashLink from "./LanguageHashLink";

import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  var innerWidth = window.innerWidth;
  return (
    <footer className="footer p-2 mx-auto mx-2">
      <div className="container flex mx-auto items-center justify-between">
        <div className="footer__addr">
          <h1 className="footer__logo" style={{ marginLeft: "-15px" }}>
            <LanguageHashLink to="/">
              <img
                className="footer-logo"
                style={{ height: "5rem" }}
                src={footerLogo}
              />
            </LanguageHashLink>
            {isMobile && (
              <div className="socials">
                <a
                  href="https://www.linkedin.com/company/womenworkplaceindex/"
                  target="_blank"
                >
                  <img src={linkedinLogo} />
                </a>
                <a
                  href="https://www.instagram.com/newgirlsclub_official/"
                  target="_blank"
                >
                  <img src={instagramLogo} />
                </a>
                <a href="https://www.facebook.com" target="_blank">
                  <img src={facebookLogo} />
                </a>
              </div>
            )}
          </h1>
        </div>
        <hr />

        {!isMobile ? (
          <div className="footer__nav">
            <div className="column">
              <ul>
                <li>
                  <LanguageHashLink to="about-us">
                    <h2 className="footer-heading-text">
                      {t("aboutUs.aboutUs")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="methodology">
                    <h2 className="footer-heading-text">
                      {t("methodology.methodology")}
                    </h2>
                  </LanguageHashLink>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>
                  <LanguageHashLink to="company-dashboard">
                    <h2 className="footer-heading-text">
                      {t("dashboard.dashboard")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="join-us">
                    <h2 className="footer-heading-text">
                      {t("joinUs.joinUs")}
                    </h2>
                  </LanguageHashLink>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>
                  <LanguageHashLink to="supporting-organisations">
                    <h2 className="footer-heading-text">
                      {t("homepage.supportingOrganisationsTitle")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="latest-news">
                    <h2 className="footer-heading-text">
                      {t("news.whatsNew")}
                    </h2>
                  </LanguageHashLink>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>
                  <LanguageHashLink to="founders-message">
                    <h2 className="footer-heading-text">
                      {t("foundersMessage.title")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="in-the-press">
                    <h2 className="footer-heading-text">
                      {t("news.inThePress")}
                    </h2>
                  </LanguageHashLink>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="footer__nav" style={{ marginLeft: "28px" }}>
            <div className="column">
              <ul>
                <li>
                  <LanguageHashLink to="about-us">
                    <h2 className="footer-heading-text">
                      {t("aboutUs.aboutUs")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="methodology">
                    <h2 className="footer-heading-text">
                      {t("methodology.methodology")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="company-dashboard">
                    <h2 className="footer-heading-text">
                      {t("dashboard.dashboard")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="join-us">
                    <h2 className="footer-heading-text">
                      {t("joinUs.joinUs")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="latest-news">
                    <h2 className="footer-heading-text">
                      {t("news.whatsNew")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="supporting-organisations">
                    <h2 className="footer-heading-text">
                      {t("homepage.supportingOrganisationsTitle")}
                    </h2>
                  </LanguageHashLink>
                </li>
                <li>
                  <LanguageHashLink to="founders-message">
                    <h2 className="footer-heading-text">
                      {t("foundersMessage.title")}
                    </h2>
                  </LanguageHashLink>
                </li>
              </ul>
            </div>
            <div className="tell-us-more">
              <p className="tell-us-more-text">{t("joinUs.tellUsMore")}</p>
              <LanguageHashLink
                className="mx-auto"
                to="join-us/#join-us-form"
                style={{ filter: "none" }}
              >
                <button className="questionnaire-button-mobile">
                  {t("joinUs.questionnaire")}
                </button>
              </LanguageHashLink>
            </div>
          </div>
        )}
      </div>
      {!isMobile && (
        <div>
          <div className="questionnaire">
            <p className="survey-text">{t("joinUs.tellUsMore")}</p>
            <LanguageHashLink
              className="m-auto"
              to="join-us/#join-us-form"
            >
              <button className="questionnaire-button">
                {t("joinUs.questionnaire")}
              </button>
            </LanguageHashLink>
          </div>
          <hr className="line"></hr>
          <div className="footer__bottom">
            <p>
              © Copyright 2024 Women Workplace Index Co. Ltd. All Rights
              Reserved.
            </p>
            <div className="footer__bottom_right">
              <div className="socials">
                <a
                  href="https://www.linkedin.com/company/womenworkplaceindex/"
                  target="_blank"
                >
                  <img src={linkedinLogo} />
                </a>
                <a
                  href="https://www.instagram.com/newgirlsclub_official/"
                  target="_blank"
                >
                  <img src={instagramLogo} />
                </a>
                <a to="https://www.facebook.com" target="_blank">
                  <img src={facebookLogo} />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}
