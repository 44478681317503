import { TailSpin } from 'react-loader-spinner'

const LoadingSpinner = () => {
  return (
    <div className='flex justify-center m-20'>
      <TailSpin
      visible={true}
      height="80"
      width="80"
      color="#760326"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      />
    </div>
  );
};

export default LoadingSpinner;
