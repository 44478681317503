import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-submenu/dist/index.css";
import "@fortawesome/fontawesome-free/css/all.css";

import "./fonts/sofiapro-light.otf";
import "./fonts/sofiapro-bold.ttf";

import Footer from "./components/footer";
import { Home } from "./components/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AboutUs } from "./components/AboutUs";
import NavigationBar from "./components/NavigationBar";
import ResearchAndMethodology from "./components/ResearchAndMethodology";
import BecomeAWWIEmployer from "./components/BecomeAWWIEmployer";
import LatestNews from "./components/LatestNews";
import { Analytics } from "@vercel/analytics/react";
import SnackbarProvider from "react-simple-snackbar";
import SupportingOrganisations from "./components/SupportingOrganisations";
import FoundersMessage from "./components/FoundersMessage";
import CompanyScorecard from "./components/CompanyScorecard";
import InThePress from "./components/InThePress";
import ScrollToAnchor from "./components/ScrollToAnchor";
import "./i18n";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams, useNavigate, Navigate, Outlet } from "react-router-dom";

function LanguageWrapper() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!lang || !Object.keys(i18n.options.resources).includes(lang)) {
      navigate("/en", { replace: true });
    } else {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, navigate]);

  return (
    <>
      <NavigationBar />
      <div style={{ height: "100vh", paddingTop: "4rem", overflow: "scroll" }}>
        <Outlet />
        <Footer />
      </div>
    </>
  );
}

function App() {
  return (
    <>
      <SnackbarProvider>
        <Router>
          <Routes>
            <Route path="/:lang" element={<LanguageWrapper />}>
              <Route index element={<Home />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="methodology" element={<ResearchAndMethodology />} />
              <Route path="join-us" element={<BecomeAWWIEmployer />} />
              <Route path="founders-message" element={<FoundersMessage />} />
              <Route path="company-dashboard" element={<CompanyScorecard />} />
              <Route path="latest-news" element={<LatestNews />} />
              <Route path="in-the-press" element={<InThePress />} />
              <Route
                path="supporting-organisations"
                element={<SupportingOrganisations />}
              />
            </Route>
            <Route path="*" element={<Navigate replace to="/en" />} />
          </Routes>
          <ScrollToAnchor />
        </Router>
        <Analytics />
      </SnackbarProvider>
    </>
  );
}

export default App;
