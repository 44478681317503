import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import zht from './translations/zht.json';
import zhs from './translations/zhs.json';
// import jp from './translations/jp.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    zht: { translation: zht },
    zhs: { translation: zhs },
    // jp: { translation: jp }
  },
  lng: 'en', // zht
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
});

export default i18n;