import React from "react";
import "../InThePress.css";
import Microlink from "@microlink/react";

import { useTranslation } from "react-i18next";

const InThePress = () => {
  const { t } = useTranslation();

  return (
    <div className="in-the-press">
      <div className="in-the-press-header">
        <p className="in-the-press-heading">{t("news.inThePress")}</p>
        <p className="in-the-press-line" />
      </div>

      <div className="in-the-press-links-container">
        <div className="md:grid md:grid-cols-3 md:gap-3">
          <Microlink
            url="https://www.scmp.com/business/companies/article/3256829/new-women-workplace-index-track-female-friendly-office-policies-hong-kong-asia"
            size="large"
          />
          <Microlink
            url="https://www.thestandard.com.hk/section-news/section/4/261572/Women-get-guide-to-working-environment-in-companies"
            size="large"
          />
          <Microlink
            url="https://www.hk01.com/article/1004433?utm_source=01appshare&utm_medium=referral#media_id=7543171"
          
            size="large"
          />
          <Microlink
            url="https://finance.mingpao.com/fin/instantf/20240326/1711447197189/%e8%a2%81%e6%b7%91%e7%90%b4-%e3%80%8a%e5%a5%b3%e6%80%a7%e8%81%b7%e5%a0%b4%e6%8c%87%e6%95%b8%e3%80%8b%ef%bc%88wwi%ef%bc%89%e4%bb%8a%e6%8e%a8%e5%87%ba-%e4%bf%83%e9%80%b2%e5%8f%af%e6%8c%81%e7%ba%8c%e7%99%bc%e5%b1%95"
            size="large"
          />
          <Microlink
            url="https://news.tvb.com/tc/local/6602ca4716767692e73ed3a1/%E6%B8%AF%E6%BE%B3-%E6%9C%89%E5%9C%98%E9%9A%8A%E5%B0%87%E6%95%B4%E7%90%86%E5%8F%8A%E7%99%BC%E5%B8%83%E6%9C%AC%E6%B8%AF%E5%85%AC%E5%8F%B8%E5%A5%B3%E6%80%A7%E8%81%B7%E5%A0%B4%E6%94%BF%E7%AD%96%E7%AD%89%E8%B3%87%E8%A8%8A-%E5%86%80%E5%8A%A9%E6%94%B9%E5%96%84%E8%81%B7%E5%A0%B4%E7%92%B0%E5%A2%83"
            size="large"
          />
          <Microlink
            url="https://news.mingpao.com/pns/%e7%b6%93%e6%bf%9f/article/20240402/s00004/1711987336464/wwi-%e5%a5%b3%e6%80%a7%e7%82%ba%e8%81%b7%e5%a0%b4%e6%8f%90%e4%be%9b%e4%b8%8d%e5%90%8c%e6%80%9d%e8%80%83%e6%96%b9%e5%bc%8f"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default InThePress;
