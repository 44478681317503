import "../ContactUs.css"
import React from 'react'
import crossLogoRed from '../images/crosslogored.png';
import checkMarkGif from '../images/icons8-checkmark.gif';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import emailjs from 'emailjs-com';
import { useSnackbar } from 'react-simple-snackbar'
import { useState } from 'react';

import { useTranslation } from "react-i18next";

const SERVICE_ID = "service_49abyxy";
const TEMPLATE_ID = "template_0gjw9cq"
const USER_ID = "MoUOZfHb4t2gNsEFj"

export const ContactUs = () => {
    const { t } = useTranslation();

    const options = {
        style: {
          backgroundColor: 'green',
          border: '2px solid lightgreen',
          color: 'lightblue',
          fontFamily: 'Menlo, monospace',
          textAlign: 'center',
        },
      }
    const [openSnackbar, closeSnackbar] = useSnackbar(options)
    const [showModal, setShowModal] = useState(false);


    const handleOnSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
        .then((result) => {
            console.log(result.text);
            openSnackbar('Your email has been sent successfully!')
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
    };

    return (
        <div className="gray-rectangle">
          <div id='contact-us' className={`contact-us ${showModal ? 'blur-effect' : ''}`}>
              <h1 className="title">
                  {t("contactUs.getInTouch")}
              </h1>
              <div className="email-line">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("contactUs.getInTouchContent"),
                  }}
                ></p>
              </div>
              {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScwpN7HDtL8cM_GQVgl8c5Keo89C5m6RmBuK1FBQV-7fF1Q8Q/viewform?embedded=true" width="640" height="1834" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
              {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd_v69SxtNien9mw410a_9IU74F3l1jxEwG477j2C3DZVCFtg/viewform?embedded=true" width="640" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
              <div className="form-container">
                <Form className='form' onSubmit={handleOnSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicCompanyName">
                        <Form.Control required type="text" placeholder={t("contactUs.form.placeholders.companyName")} name="company_name"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPersonName">
                        <Form.Control required type="text" placeholder={t("contactUs.form.placeholders.personName")} name="person_name"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control required type="email" placeholder={t("contactUs.form.placeholders.email")} name="email"/>
                    </Form.Group>
                    <Row>
                        <Col xs={6} sm={5}>
                            <Form.Group className="mb-3" controlId="countryCode">
                                <Form.Control required type="text" placeholder={t("contactUs.form.placeholders.countryCode")} name="country_code"/>
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={7}>
                            <Form.Group className="mb-3" controlId="ContactNo">
                                <Form.Control required type="text" placeholder={t("contactUs.form.placeholders.contactNo")} name="contact_no"/>
                            </Form.Group>
                        </Col>
                      </Row>
                    <div className="d-flex justify-content-center">
                      <Button className="primary-button" variant="primary" type="submit" >
                          {t("contactUs.form.submitButton")}
                      </Button>
                    </div>
                </Form>
              </div>

              {showModal && (
                <div className="modal-overlay">
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Body>
                      <img src={crossLogoRed} alt="Cross Logo" onClick={handleCloseModal} style={{ width: '50px', height: '50px', position: 'absolute', top: '-15px', right: '-15px' }} />
                      <p className="contact-ty-text">{t("contactUs.modal.thankYouShort")}</p>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={checkMarkGif} alt="Check Mark" style={{ marginTop: '1rem', width: '5rem', height: '5rem' }} />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p className="contact-ty-text-2" dangerouslySetInnerHTML={{ __html: t("contactUs.modal.thankYouLong") }}></p>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              )}
          </div>
        </div>
    )
}