import React from "react";
import "../aboutUs.css";
import missionImg from "../images/secondaryBg.svg";
import whatWeDoGroupImg from "../images/about us.jpg";
import changeIcon from "../images/what_we_do_change_icon.png";
import practiceIcon from "../images/what_we_do_practice_icon.png";
import disclosureIcon from "../images/what_we_do_disclosure_icon.png";
import databaseIcon from "../images/certification_database_icon.png";
import certificationIcon from "../images/certification_certification_icon.png";
import asiaIcon from "../images/certification_asia_icon.png";
import indexIcon from "../images/certification_index_icon.png";

import { useTranslation } from "react-i18next";

export const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="about-us">
      <div className="header-image">
        <p>{t("homepage.whoWeAre")}</p>
      </div>
      <div className="wide-content">
        <div className="md:grid md:grid-cols-12 md:gap-6 content-gray-bg">
          <div className="hidden md:block md:col-span-6">
            <img className="h-100" src={missionImg} alt="Mission" />
          </div>
          <div className="md:col-span-6 m-auto text-container">
            <h1
              id="vision-mission"
              className="title hidden md:block"
              style={{
                marginBottom: "2rem",
                fontSize: "2.5rem",
                textAlign: "left",
              }}
            >
              {t("aboutUs.visionAndMission")}
            </h1>
            <h2 className="secondary-title">{t("aboutUs.halfSky")}</h2>
            <br />
            <span className="text">{t("aboutUs.visionAndMissionContent")}</span>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="text-container">
          <h1 id="what-we-do" className="title">
            {t("aboutUs.whatWeDo")}
          </h1>

          <div className="text">
            <p
              dangerouslySetInnerHTML={{
                __html: t("aboutUs.indexDescription"),
              }}
            ></p>
          </div>
          <div className="overflow-scroll">
            <div id="what-we-do-card-container">
              <div className="what-we-do-card">
                <img
                  src={disclosureIcon}
                  className="icon"
                  alt="encourage disclosure"
                />
                <div className="card-title">
                  {t("aboutUs.whatWeDoItem.disclosureTitle")}
                </div>
                <div className="card-content">
                  {t("aboutUs.whatWeDoItem.disclosureContent")}
                </div>
              </div>
              <div className="what-we-do-card">
                <img
                  src={practiceIcon}
                  className="icon"
                  alt="improve best practices"
                />
                <div className="card-title">
                  {t("aboutUs.whatWeDoItem.practiceTitle")}
                </div>
                <div className="card-content">
                  {t("aboutUs.whatWeDoItem.practiceContent")}
                </div>
              </div>
              <div className="what-we-do-card">
                <img
                  src={changeIcon}
                  className="icon"
                  alt="accelerate changes"
                />
                <div className="card-title">
                  {t("aboutUs.whatWeDoItem.changeTitle")}
                </div>
                <div className="card-content">
                  {t("aboutUs.whatWeDoItem.changeContent")}
                </div>
              </div>
            </div>
          </div>

          <div>
            <p
              className="text gray-text"
              dangerouslySetInnerHTML={{
                __html: t("aboutUs.indexRole"),
              }}
            ></p>
            <p
              className="text gray-text"
              dangerouslySetInnerHTML={{
                __html: t("aboutUs.indexImportance"),
              }}
            ></p>
          </div>
        </div>
      </div>
      <div className="wide-content mt-20">
        <div className="md:grid md:grid-cols-12">
          <div className="md:col-span-6">
            <img
              className=""
              src={whatWeDoGroupImg}
              alt="Social sustainability"
            />
          </div>
          <div className="md:col-span-6 text-container m-auto">
            <p className="secondary-title">{t("aboutUs.beyondEmpowerment")}</p>
            <br />
            <p
              className="text"
              dangerouslySetInnerHTML={{
                __html: t("aboutUs.impactOnHumanity"),
              }}
            ></p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="text-container content-gray-bg">
          <h1 id="certification-and-index" className="title">
            {t("aboutUs.certification")}
          </h1>
          <div className="overflow-scroll">
            <div id="certification-container">
              <div className="certification-card">
                <div className="card-subtitle">
                  {t("aboutUs.certificationItem.databaseYear")}
                </div>
                <img src={databaseIcon} className="icon" alt="WWI database" />
                <div className="card-title">
                  {t("aboutUs.certificationItem.databaseTitle")}
                </div>
                <div
                  className="card-content"
                  dangerouslySetInnerHTML={{
                    __html: t("aboutUs.certificationItem.databaseContent"),
                  }}
                ></div>
              </div>
              <div className="certification-card">
                <div className="card-subtitle">
                  {t("aboutUs.certificationItem.certificationYear")}
                </div>
                <img
                  src={certificationIcon}
                  className="icon"
                  alt="WWI certification"
                />
                <div className="card-title highlight dashed">
                  {t("aboutUs.certificationItem.certificationTitle")}
                </div>
                <div
                  className="card-content"
                  dangerouslySetInnerHTML={{
                    __html: t("aboutUs.certificationItem.certificationContent"),
                  }}
                ></div>
              </div>
              <div className="certification-card">
                <div className="card-subtitle">
                  {t("aboutUs.certificationItem.asiaYear")}
                </div>
                <img src={asiaIcon} className="icon" alt="Asia expansion" />
                <div className="card-title dashed">
                  {t("aboutUs.certificationItem.asiaTitle")}
                </div>
                <div
                  className="card-content"
                  dangerouslySetInnerHTML={{
                    __html: t("aboutUs.certificationItem.asiaContent"),
                  }}
                ></div>
              </div>
              <div className="certification-card">
                <div className="card-subtitle">
                  {t("aboutUs.certificationItem.indexYear")}
                </div>
                <img src={indexIcon} className="icon" alt="WWI Index" />
                <div className="card-title highlight dashed">
                  {t("aboutUs.certificationItem.indexTitle")}
                </div>
                <div
                  className="card-content"
                  dangerouslySetInnerHTML={{
                    __html: t("aboutUs.certificationItem.indexContent"),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
